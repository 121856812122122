import { withScope } from '@sentry/solidstart';
import type { AnyVariables, DocumentInput } from '@urql/core';

class FormFilterWarning extends Error {}

export function formDataToObject<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	query: DocumentInput<Data, Variables>,
	data: FormData,
	transform?: (data: FormData) => Partial<Variables>,
): Variables {
	// Filter out inappropriate values
	// @ts-expect-error
	const possibleValues: Array<string> = query.definitions[0].variableDefinitions.map(
		// @ts-expect-error
		({ variable }) => variable.name.value,
	);

	const out: Record<string, string> = {};
	const rawData: Record<string, string> = { ...Object.fromEntries(data), ...(transform ? transform(data) : undefined) };
	Object.entries(rawData).forEach(([key, value]) => {
		// Anything that starts with '__' can be considered internal/implementation and should automatically be filtered
		if (key.startsWith('__')) {
			return;
		}

		if (!possibleValues.includes(key) && !key.includes('password')) {
			withScope((scope) => {
				scope.setLevel('warning');
				scope.setExtra('inputName', key);
				scope.setExtra('graphQLQuery', query);
				scope.captureException(new FormFilterWarning('Filtered unknown input element from query'));
			});
			return;
		}

		out[key] = value;
	});

	return out as Variables;
}
